import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import turkeyIcon from "@/assets/icons/turkeyIcon.vue";
import ukIcon from "@/assets/icons/ukIcon.vue";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // default - only for display purposes
    values: {
      turkeyIcon: {
        component: turkeyIcon,
      },
      ukIcon: {
        component: ukIcon,
      },
    },
  },
});
