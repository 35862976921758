import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import moment from "moment";
Vue.prototype.moment = moment;
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
import io from "socket.io-client";
const ceviriInitial = {
  pending: {
    tr: "Son Aramalar",
    en: "Pending",
  },
  active: { tr: "Devam Eden", en: "Active" },
  approved: { tr: "Gelecek", en: "Approved" },
  completed: { tr: "Geçmiş", en: "Completed" },
  cancelled: { tr: "İptal", en: "Cancelled" },
  approremovedved: { tr: "Onay Kaldırıldı", en: "Confirmation Removed" },
  billed: { tr: "Faturalandırılmış", en: "Billed" },
  notbilled: { tr: "Faturalandırılmamış", en: "Not Billed" },
  canceledbill: { tr: "Faturası İptal Edilmiş", en: "Cancelled Bill" },
  paymentError: { tr: "Ödeme Hatalı", en: "Payment Error" },
  noShowCancelled: { tr: "No-Show İptaller", en: "No-Show Cancelled" },
  earlyReturnRequest: { tr: "Erken İade Talebi", en: "Early Return Request" },
  earlyReturnFinancialConfirmation: {
    tr: "Erken İade Finansal Onay",
    en: "Early Return Financial Confirmation",
  },
  confirmedEarlyReturn:{tr: "Onaylanan Erken İade", en: "Approved Early Returns" },
  earlyReturnRejected: { tr: "Erken İade Red", en: "Early Return Rejected" },
  tc: { tr: "TC Kimlik", en: "Identity" },
  identity: { tr: "TC Kimlik", en: "Identity" },
  passport: { tr: "Pasaport", en: "Passport Number" },
  agent: { tr: "Agent", en: "Agent" },
  Developer: { tr: "Yazılım Uzmanı", en: "Developer" },
  HumanResources: { tr: "İnsan Kaynakları", en: "Human Resources" },
  CallCenter: { tr: "Çağrı Merkezi", en: "Call Center" },
  Marketing: { tr: "Pazarlama", en: "Marketing" },
  CustomerExperience: { tr: "Müşteri Deneyimi", en: "Customer Experience" },
  OfficeStaff: { tr: "Ofis Çalışanı", en: "Office Staff" },
  user: { tr: "User", en: "User" },
  partner: { tr: "Partner", en: "Partner" },
  manuel: { tr: "Düz Vites", en: "Manual" },
  automatic: { tr: "Otomatik Vites", en: "Automatic" },
  diesel: { tr: "Dizel", en: "Diesel" },
  gas: { tr: "Benzin", en: "Gas" },
  lpg: { tr: "LPG", en: "LPG" },
  hybrit: { tr: "Hibrit", en: "Hybrit" },
  electric: { tr: "Elektrikli", en: "Electric" },
  yakit_politikasi: { tr: "Yakıt Politikası", en: "Fuel Policy" },
  full: { tr: "Dolu Depo Yakıt", en: "Full" },
  sameLevel: { tr: "Aynı Seviye Yakıt", en: "Same Level" },
  inTerminal: { tr: "Terminal İçi", en: "In Terminal" },
  centralOffice: { tr: "Merkez Ofis", en: "Central Office" },
  welcoming: { tr: "Karşılama", en: "Welcoming" },
  freeShuttle: { tr: "Ücretsiz Servis", en: "Free Shuttle" },
  vale: { tr: "Vale", en: "Vale" },
  economic: { tr: "Ekonomik", en: "Economic" },
  medium: { tr: "Standart", en: "Medium" },
  luxury: { tr: "Lüks", en: "Luxury" },
  unspecified: { tr: "Belirtilmemiş", en: "Unspecified" },
  car: { tr: "Araç", en: "Car" },
  small: { tr: "Küçük", en: "Small" },
  large: { tr: "Büyük", en: "Large" },
  stationWagon: { tr: "Station Wagon", en: "Station Wagon" },
  premium: { tr: "Premium", en: "Premium" },
  minivan: { tr: "Minivan", en: "Minivan" },
  monospace: { tr: "Monospace", en: "Monospace" },
  commercial: { tr: "Ticari", en: "Commercial" },
  trailerCaravan: { tr: "Çekme Karavan", en: "Trailer Caravan" },
  suv: { tr: "Suv", en: "Suv" },
  motorhome: { tr: "Moto Karavan", en: "Motor Home" },
  classicAmerican: { tr: "Klasik Amerikan", en: "Classic American" },
  classicVosVos: { tr: "Classic VosVos", en: "Classic VosVos" },
  bus: { tr: "Otobüs", en: "bus" },
  minibus: { tr: "Minibüs", en: "Minibus" },
  motorcycle: { tr: "Motosiklet", en: "Motorcycle" },
  scooter: { tr: "Scooter", en: "Scooter" },
  transfer: { tr: "Transfer", en: "Transfer" },
  luxury: { tr: "Luxury", en: "Luxury" },
  vipMinibus: { tr: "Vip Minibüs", en: "Vip Minibus" },
  yacht: { tr: "Yat", en: "Yacht" },
  catamaran: { tr: "Katamaran", en: "Catamaran" },
  electric: { tr: "Elektrikli", en: "Electric" },
  sedan: { tr: "Sedan", en: "Sedan" },
  hatchback: { tr: "Hatchback", en: "Hatchback" },
  crossover: { tr: "Crossover", en: "Crossover" },
  wagon: { tr: "Wagon", en: "Wagon" },
  coupe: { tr: "Coupe", en: "Coupe" },
  pickup: { tr: "Pickup", en: "Pickup" },
  compact: { tr: "Compact", en: "Compact" },
  cabriolet: { tr: "Cabriolet", en: "Cabriolet" },
  van: { tr: "Van", en: "Van" },
  "3dPos": { tr: "3D Ödeme", en: "3D Payment" },
  smsPos: { tr: "SMS ile Ödeme", en: "Pay With SMS" },
  noneSecurePos: { tr: "Sanal POS", en: "Virtual POS" },
  debit: { tr: "Cari Hesap", en: "Current Balance" },
  default: { tr: "", en: "" },
};
const state = {
  sidebarShow: "responsive",
  copiedCampaign: [],
  sidebarMinimize: false,
  GetServerData: null,
  locale: "tr",
  currency: "TRY",
  ceviri: ceviriInitial,
  mutabakatCeviri: {
    approved: "Onaylanan",
    completed: "Tamamlanan",
    pending: "Beklemede",
    rejects: "Reddedilen",
    daily: "Günlük",
    monthly: "Aylık",
  },
  generalPriceRate: 0,
  campaignDates: {
    pickup: {
      start: moment().format(),
      end: moment().format(),
    },
    dropoff: {
      start: moment().format(),
      end: moment().format(),
    },
    create: {
      start: moment().format(),
      end: moment().format(),
    },
  },
};

const actions = {
  successSwal({ commit }, params) {
    let timerInterval;
    Vue.swal
      .fire({
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        text: params.text,
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
        willClose: () => {
          clearInterval(timerInterval);
        },
      })
      .then((result) => {
        if (result.dismiss === Vue.swal.DismissReason.timer) {
          params.refresh ? location.reload() : "";
        }
      });
  },
  setSidebarShow({ commit }, data) {
    commit("sidebarShow", data);
  },
  setDefaultCampaignDates({ commit }) {
    commit("defaultCampaignDates");
  },
};

const mutations = {
  setLanguage(state) {
    let preStateCeviri = {};
    Object.keys(ceviriInitial).forEach((key) => {
      preStateCeviri[key] = ceviriInitial[key][state?.locale || "tr"];
    });

    state.ceviri = preStateCeviri;
  },
  setCurrency(state, data) {
    state.currency = data;
  },
  setLocale(state, data) {
    state.locale = data;
  },

  // kampanyayı kopyalayıp yeni kampanya aç
  setCopiedCampaign(state, data) {
    state.copiedCampaign = data;
  },
  sidebarShow(state, val) {
    state.sidebarShow = val;
  },

  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  closeSidebar(state) {
    state.sidebarShow = false;
  },
  openSidebar(state) {
    state.sidebarShow = "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  UpdateServerData(state, ServerData) {
    state.GetServerData = ServerData;
  },
  updateGeneralPriceRate(state, value) {
    state.generalPriceRate = value;
  },
  defaultCampaignDates(state) {
    state.campaignDates = Object.assign(
      {},
      {
        pickup: {
          start: moment().format(),
          end: moment().format(),
        },
        dropoff: {
          start: moment().format(),
          end: moment().format(),
        },
        create: {
          start: moment().format(),
          end: moment().format(),
        },
      }
    );
  },
};

const getters = {
  IsLogin: function (state) {
    return state.GetServerData.status && state.GetServerData.status == "Active";
  },
  socket: () => io(process.env.VUE_APP_API_URL),
};
export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
///////
