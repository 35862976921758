import Vue from "vue";
import Router from "vue-router";
import { appConfig } from "@/config.js";
import store from "../store";
import axios from "axios";

axios.defaults.withCredentials = true;
// set default header lang for all request
axios.defaults.headers.common["Accept-Language"] = store.state.locale;

if (store?.state?.GetServerData?.partnerID) {
  axios.defaults.headers.common["partnerID"] =
    store?.state?.GetServerData?.partnerID;
}

Vue.use(Router);

const router = new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Ana Sayfa",
      component: () => import("@/containers/TheContainer"),
      children: [
        {
          path: "profile",
          name: "Profil",
          component: () => import("@/views/Profile"),
          meta: {
            authRequired: true,
            permission: "Moderatör",
          },
        },
        {
          path: "dashboard",
          name: "Özet",
          component: () => import("@/views/Dashboard"),
          meta: {
            authRequired: true,
            permission: "Moderatör",
          },
        },
        {
          path: "users",
          redirect: "/users/",
          name: "Kullanıcılar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "member",
              name: "Yeni Müşteri",
              component: () => import("@/views/users/Members"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
            {
              path: "admin",
              name: "Admin Kullanıcıları",
              component: () => import("@/views/users/Admins"),
              meta: {
                authRequired: true,
              },
            },

            {
              path: "vendor",
              name: "Firma Kullanıcıları",
              component: () => import("@/views/users/Vendors"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "partner",
              name: "Partner Kullanıcıları",
              component: () => import("@/views/users/Partners"),
              meta: {
                authRequired: true,
              },
            },

            {
              path: "tester",
              name: "Test Kullanıcıları",
              component: () => import("@/views/users/Testers"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "mernis",
              name: "Mernis Kontrol",
              component: () => import("@/views/users/Mernis"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
            {
              path: "role",
              name: "Grup Yetkilendirme",
              component: () => import("@/views/users/Role"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
          ],
        },

        {
          path: "user-delete-requests",
          redirect: "/user-delete-requests/",
          name: "Hesap Silme Talepleri",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/users/UserDeleteRequests"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },

        {
          path: "reservation",
          redirect: "/reservation",
          name: "Rezervasyonlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "index/",
              name: "Reservasyonları Görüntüle",
              component: () => import("@/views/reservation/Index"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
            {
              path: "index/:reservationStatus",
              name: "",
              component: () => import("@/views/reservation/Index"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
            {
              path: "detail/:reservationCode",
              name: "Rezervasyon Detayı",
              component: () => import("@/views/reservation/Detail"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
            {
              path: "new",
              name: "Yeni Rezervasyon",
              component: () => import("@/views/reservation/New"),
              meta: {
                authRequired: true,
                permission: "Moderatör",
              },
            },
          ],
        },
        {
          path: "comment",
          name: "Yorumlar",
          component: () => import("@/views/comment/Search"),
          meta: {
            authRequired: true,
          },
        },

        {
          path: "product",
          redirect: "/product/search",
          name: "Ek Ürün & Sigorta",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "search",
              name: "Ek Ürün Arama",
              component: () => import("@/views/product/search"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "vendor",
          redirect: "/vendor/",
          name: "Firmalar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/vendor/Vendors"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "cars",
              name: "Firma Araçları",
              component: () => import("@/views/vendor/Cars"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "cars/:vendorSlug",
              name: "Lokasyondaki Firma Araçları",
              component: () => import("@/views/vendor/Cars"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "locations",
              name: "Firma Lokasyonları",
              component: () => import("@/views/vendor/Locations"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "product",
              name: "Ek Ürünler",
              component: () => import("@/views/vendor/product"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "rate",
              name: "Firma Komisyon Oranları",
              component: () => import("@/views/vendor/Rate"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "vendor-strike-through-price",
              name: "Tedarikci Üstü Çizili Fiyat Belirleme",
              component: () =>
                import("@/views/vendor/VendorStrikeThroughPrice"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "partner",
          redirect: "/partner/",
          name: "Partnerler",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/partner/Partners"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "accounting",
          redirect: "/accounting/",
          name: "Muhasebe",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "invoices",
              name: "Faturalar",
              component: () => import("@/views/accounting/Invoices"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "locations",
          redirect: "/locations/",
          name: "Lokasyonlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/locations/Locations"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },

        {
          path: "citylocations",
          redirect: "/citylocations/",
          name: "Şehir Lokasyonları",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/citylocations/Citylocations"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "campaign",
          redirect: "/campaign/",
          name: "Kampanyalar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/campaign/Search"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "coupons",
          redirect: "/coupons/",
          name: "Kuponlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/coupons/Search"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "blog",
          redirect: "/blog/",
          name: "Blog",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/blog/Blog"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "cars",
          redirect: "/cars/",
          name: "Araçlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/cars/Cars"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
        {
          path: "contact",
          redirect: "/contact/",
          name: "İletişim Formu",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "",
              component: () => import("@/views/contact/Search"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },

        {
          path: "consensus",
          redirect: "/consensus",
          name: "Mutabakatlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "index/",
              name: "",
              component: () => import("@/views/consensus/Index"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "index/:consensusStatus",
              name: "",
              component: () => import("@/views/consensus/Index"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },

        {
          path: "settings",
          redirect: "/settings/",
          name: "Ayarlar",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "settings",
              name: "Genel Ayarlar",
              component: () => import("@/views/settings/Settings"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "monthlyrentalpage",
              name: "Aylık Kiralama Sayfası",
              component: () => import("@/views/settings/MonthlyRentalPage"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "questions",
              name: "Sorular",
              component: () => import("@/views/settings/Questions"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "vendor",
              name: "İş Ortakarı Sıralama",
              component: () => import("@/views/settings/Vendor"),
              meta: {
                authRequired: true,
              },
            },
            {
              path: "contracts",
              name: "Sözleşmeler",
              component: () => import("@/views/settings/Contracts"),
              meta: {
                authRequired: true,
              },
            },
          ],
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "500",
          name: "Page500",
          component: () => import("@/views/pages/Page500"),
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/pages/Login"),
    },
    {
      path: "/404",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
  ];
}

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next("/404");
    return;
  }

  if (to.path == "/login") {
    signOutUser();
    next();
    return;
  }

  return axios
    .get(process.env.VUE_APP_API_URL + "admin/session")
    .then((result) => {
      store.commit("UpdateServerData", result.data);
      if (!store.getters.IsLogin) {
        next("/login");
        return;
      }

      next();
    })
    .catch((error) => {
      signOutUser();
      console.error(error);
      next("/login");
    });

  function signOutUser() {
    return axios.get(process.env.VUE_APP_API_URL + "admin/signout");
  }
});

export default router;
