var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        height: "800px",
        width: "800px",
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        staticStyle: { fill: "#ED1F34" },
        attrs: {
          d: "M8.258,126.624v258.753c0,19.763,16.022,35.785,35.785,35.785h423.914\r\n\tc19.763,0,35.785-16.022,35.785-35.785V126.624c0-19.763-16.022-35.785-35.785-35.785H44.043\r\n\tC24.28,90.839,8.258,106.86,8.258,126.624z",
        },
      }),
      _c("g", [
        _c("path", {
          staticStyle: { fill: "#FFFFFF" },
          attrs: {
            d: "M210.305,337.677c-45.109,0-81.677-36.568-81.677-81.677s36.568-81.677,81.677-81.677\r\n\t\tc22.245,0,42.402,8.906,57.133,23.33c-19.526-31.397-54.323-52.311-94.019-52.311c-61.115,0-110.658,49.543-110.658,110.658\r\n\t\ts49.543,110.658,110.658,110.658c39.696,0,74.492-20.915,94.019-52.312C252.708,328.771,232.55,337.677,210.305,337.677z",
          },
        }),
        _c("polygon", {
          staticStyle: { fill: "#FFFFFF" },
          attrs: {
            points:
              "277.628,256 309.847,243.659 311.627,209.204 333.32,236.033 366.638,227.079 347.826,256 \r\n\t\t366.638,284.921 333.32,275.967 311.627,302.796 309.847,268.341 \t",
          },
        }),
      ]),
      _c("g", [
        _c("path", {
          staticStyle: { fill: "#121B21" },
          attrs: {
            d: "M373.32,222.225c-2.012-2.768-5.522-4.01-8.824-3.122l-28.132,7.56l-18.315-22.651\r\n\t\tc-2.15-2.66-5.718-3.719-8.974-2.661c-3.254,1.057-5.519,4.011-5.695,7.428l-1.503,29.092l-27.202,10.418\r\n\t\tc-3.194,1.223-5.304,4.291-5.304,7.712s2.11,6.489,5.304,7.712l27.202,10.418l1.503,29.091c0.177,3.416,2.442,6.371,5.695,7.428\r\n\t\tc0.839,0.273,1.699,0.405,2.551,0.405c2.45,0,4.826-1.092,6.423-3.066l18.315-22.651l28.132,7.56\r\n\t\tc3.301,0.887,6.813-0.353,8.824-3.122c2.011-2.768,2.106-6.489,0.241-9.357L357.678,256l15.883-24.419\r\n\t\tC375.426,228.713,375.33,224.993,373.32,222.225z M340.904,260.502l7.057,10.848l-12.498-3.358\r\n\t\tc-3.152-0.846-6.511,0.243-8.564,2.782l-8.137,10.063l-0.667-12.923c-0.168-3.261-2.243-6.118-5.293-7.286l-12.084-4.629\r\n\t\tl12.084-4.629c3.05-1.168,5.124-4.024,5.293-7.286l0.667-12.923l8.137,10.063c2.053,2.539,5.415,3.627,8.564,2.782l12.498-3.358\r\n\t\tl-7.057,10.848C339.124,254.235,339.124,257.765,340.904,260.502z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#121B21" },
          attrs: {
            d: "M272.605,307.905c-3.261-2.615-7.961-2.384-10.946,0.542c-13.81,13.525-32.048,20.972-51.354,20.972\r\n\t\tc-34.701,0-64.946-24.63-71.915-58.564c-0.917-4.467-5.281-7.343-9.75-6.428c-4.468,0.917-7.345,5.283-6.428,9.75\r\n\t\tc8.539,41.579,45.588,71.757,88.094,71.757c4.804,0,9.554-0.377,14.216-1.115c-15.252,8.787-32.767,13.581-51.102,13.581\r\n\t\tc-56.463,0-102.4-45.937-102.4-102.4s45.937-102.4,102.4-102.4c18.335,0,35.851,4.795,51.102,13.581\r\n\t\tc-4.662-0.739-9.412-1.115-14.216-1.115c-42.506,0-79.555,30.178-88.094,71.757c-0.918,4.468,1.96,8.833,6.428,9.75\r\n\t\tc4.463,0.916,8.833-1.96,9.75-6.428c6.969-33.934,37.213-58.564,71.915-58.564c19.306,0,37.544,7.448,51.354,20.972\r\n\t\tc2.986,2.923,7.685,3.156,10.946,0.542c3.259-2.615,4.052-7.254,1.844-10.803c-21.888-35.195-59.657-56.207-101.03-56.207\r\n\t\tc-65.57,0-118.916,53.346-118.916,118.916s53.346,118.916,118.916,118.916c41.373,0,79.141-21.013,101.03-56.209\r\n\t\tC276.657,315.159,275.866,310.52,272.605,307.905z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#121B21" },
          attrs: {
            d: "M468.324,82.581H43.676C19.593,82.581,0,102.173,0,126.256v259.487\r\n\t\tc0,24.084,19.593,43.676,43.676,43.676h424.648c24.083,0,43.675-19.593,43.675-43.676V126.256\r\n\t\tC512,102.173,492.407,82.581,468.324,82.581z M495.484,385.743c0,14.977-12.183,27.16-27.16,27.16H43.676\r\n\t\tc-14.977,0-27.16-12.183-27.16-27.16V126.256c0-14.976,12.183-27.159,27.16-27.159h424.648c14.976,0,27.159,12.183,27.159,27.159\r\n\t\tV385.743z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }