var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        height: "800px",
        width: "800px",
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
      },
    },
    [
      _c("path", {
        staticStyle: { fill: "#103b9b" },
        attrs: {
          d: "M469.058,421.161H42.942c-19.155,0-34.684-15.528-34.684-34.684V125.523\n\tc0-19.155,15.528-34.684,34.684-34.684h426.115c19.155,0,34.684,15.528,34.684,34.684v260.954\n\tC503.741,405.632,488.213,421.161,469.058,421.161z",
        },
      }),
      _c("path", {
        staticStyle: { fill: "#ffffff" },
        attrs: {
          d: "M467.957,90.839h-44.608L256,202.405L88.65,90.839H39.271c-17.128,0-31.014,13.886-31.014,31.014\n\tv22.581L175.607,256L8.259,367.566v18.911c0,19.155,15.528,34.684,34.684,34.684H88.65L256,309.594l167.35,111.566h46.809\n\tc18.548,0,33.583-15.035,33.583-33.583v-20.012L336.392,256l167.35-111.566v-17.81C503.741,106.86,487.72,90.839,467.957,90.839z",
        },
      }),
      _c("path", {
        staticStyle: { fill: "#ed1f34" },
        attrs: {
          d: "M20.817,412.787c6.169,5.219,14.142,8.373,22.856,8.373h18.179l220.945-147.296l208.385,138.923\n\tl12.547-26.569c0-0.011,0.001-0.022,0.001-0.033L282.487,238.341L491.181,99.211c-6.169-5.219-14.142-8.373-22.856-8.373h-18.18\n\tL229.214,238.142L20.817,99.211c-7.679,6.496-12.559,16.199-12.559,27.045v0.337l220.659,147.461L20.817,412.787z",
        },
      }),
      _c("g", [
        _c("path", {
          staticStyle: { fill: "#121b21" },
          attrs: {
            d: "M229.214,246.401c-1.597,0-3.193-0.462-4.58-1.387L16.237,106.082\n\t\tc-3.794-2.529-4.82-7.657-2.29-11.451c2.529-3.794,7.658-4.819,11.451-2.29l203.816,135.877L445.566,83.968\n\t\tc3.794-2.53,8.923-1.504,11.451,2.29c2.53,3.794,1.504,8.922-2.29,11.452L233.795,245.013\n\t\tC232.408,245.938,230.811,246.401,229.214,246.401z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#121b21" },
          attrs: {
            d: "M503.734,394.452c-1.578,0-3.171-0.45-4.58-1.393L277.899,245.207\n\t\tc-2.295-1.534-3.671-4.111-3.67-6.871s1.382-5.336,3.678-6.866L486.601,92.341c3.794-2.529,8.922-1.504,11.451,2.29\n\t\tc2.53,3.794,1.504,8.922-2.29,11.452L297.363,238.348l210.967,140.978c3.792,2.535,4.812,7.662,2.277,11.454\n\t\tC509.016,393.164,506.399,394.452,503.734,394.452z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#121b21" },
          attrs: {
            d: "M61.86,429.419c-2.669,0-5.287-1.292-6.878-3.679c-2.53-3.794-1.504-8.922,2.29-11.452\n\t\tl220.943-147.296c2.775-1.85,6.387-1.849,9.161,0l208.386,138.923c3.794,2.53,4.82,7.657,2.29,11.452\n\t\tc-2.53,3.794-7.658,4.819-11.451,2.29L282.797,283.79L66.434,428.031C65.025,428.97,63.434,429.419,61.86,429.419z",
          },
        }),
        _c("path", {
          staticStyle: { fill: "#121b21" },
          attrs: {
            d: "M20.825,421.046c-2.669,0-5.287-1.292-6.878-3.679c-2.53-3.794-1.504-8.922,2.29-11.452\n\t\tl197.806-131.87L3.67,133.459c-3.792-2.535-4.812-7.662-2.277-11.454c2.532-3.792,7.662-4.812,11.453-2.278l220.659,147.461\n\t\tc2.295,1.534,3.671,4.111,3.67,6.871c-0.001,2.759-1.382,5.336-3.678,6.866L25.398,419.659\n\t\tC23.991,420.597,22.399,421.046,20.825,421.046z",
          },
        }),
      ]),
      _c("polygon", {
        staticStyle: { fill: "#ffffff" },
        attrs: {
          points:
            "503.741,211.406 300.593,211.406 300.593,90.839 211.407,90.839 211.407,211.406 \n\t8.259,211.406 8.259,300.593 211.407,300.593 211.407,421.161 300.593,421.161 300.593,300.593 503.741,300.593 ",
        },
      }),
      _c("polygon", {
        staticStyle: { fill: "#ed1f34" },
        attrs: {
          points:
            "503.741,232.051 279.948,232.051 279.948,90.839 232.052,90.839 232.052,232.051 \n\t8.259,232.051 8.259,279.948 232.052,279.948 232.052,421.161 279.948,421.161 279.948,279.948 503.741,279.948 ",
        },
      }),
      _c("path", {
        staticStyle: { fill: "#121b21" },
        attrs: {
          d: "M468.323,82.581H43.677c-24.083,0-43.676,19.592-43.676,43.676v259.487\n\tc0,24.083,19.594,43.676,43.676,43.676h424.646c24.083,0,43.676-19.592,43.676-43.676V126.256\n\tC511.999,102.173,492.406,82.581,468.323,82.581z M495.483,126.256v97.536H288.206V99.097h180.117\n\tC483.299,99.097,495.483,111.28,495.483,126.256z M43.677,99.097h180.117v124.697H16.517v-97.536\n\tC16.517,111.28,28.7,99.097,43.677,99.097z M16.517,385.742v-97.536h207.277v124.697H43.677\n\tC28.7,412.903,16.517,400.718,16.517,385.742z M468.323,412.903H288.206V288.206h149.471c4.562,0,8.258-3.697,8.258-8.258\n\ts-3.696-8.258-8.258-8.258H279.948c-4.562,0-8.258,3.697-8.258,8.258v132.955H240.31V279.948c0-4.561-3.696-8.258-8.258-8.258\n\tH16.517v-31.381h215.535c4.562,0,8.258-3.697,8.258-8.258V99.097h31.381v132.955c0,4.561,3.696,8.258,8.258,8.258h215.535v31.381\n\th-24.774c-4.562,0-8.258,3.697-8.258,8.258s3.696,8.258,8.258,8.258h24.774v97.536C495.483,400.718,483.299,412.903,468.323,412.903\n\tz",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }